export default {
	blue: {
		color: 0x0000ff,
		roughness: 0.5,
		metalness: 0.5,
		clearcoat: 0.5,
		clearcoatRoughness: 0.5,
	},
	red: {
		color: 0xff0000,
		roughness: 0.5,
		metalness: 0.5,
		clearcoat: 0.5,
		clearcoatRoughness: 0.5,
	},
	green: {
		color: 0x00ff00,
		roughness: 0.5,
		metalness: 0.5,
		clearcoat: 0.5,
		clearcoatRoughness: 0.5,
	},
  orange: {
    color: 0x0000ff,
    roughness: 0.25,
    metalness: 0.25,
    clearcoat: 0.5,
    clearcoatRoughness: 0.5,
  },
}
