import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ApproachExperienceAnimation {
  constructor(sceneObject, material) {
		// Intersection observer for sceneObject.el
		// Used to detect when the element is completely out of view
		// and reset the mix property to 0
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.intersectionRatio === 0) {
					// gsap.killTweensOf(material, { mix: true }); // kill all tweens that might be running
					// gsap.set(material, { mix: 0 });
				}
			});
		});

		// observer.observe(sceneObject.el);

		// On load, if the el is above the fold, set mix to 1.1
		if (sceneObject.el.getBoundingClientRect().top < 0) {
			gsap.set(material, { mix: 1.1 });
		}

    const enter = () => {
      gsap.to(material, {
        mix: 1.1,
        duration: 2,
        ease: "sine.inOut",
      });
    };

    const leave = () => {
      gsap.to(material, {
        mix: 0,
        duration: 1.25,
        ease: "sine.out",
      });
    };

    ScrollTrigger.create({
      trigger: sceneObject.el,
      pin: false,
      start: "0% 50%",
      end: "100% 0",
      markers: false,
      onEnter: enter,
			// onEnterBack: enter,
      onLeaveBack: leave,
    });
  }
}
