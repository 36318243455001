import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class PeopleExperienceAnimation {
  constructor(sceneObject, material) {
    // On load, if the el is above the fold, set mix to 1.1
    if (sceneObject.el.getBoundingClientRect().top < 0) {
      gsap.set(material, { mix: 1.1 });
    }

    const enter = () => {
      gsap.to(material, {
        mix: 1.1,
        duration: 2,
        ease: "sine.inOut",
      });
    };

    ScrollTrigger.create({
      trigger: sceneObject.el,
      pin: false,
      start: "top +=300 middle",
      markers: false,
      onEnter: enter,
    });
  }
}
