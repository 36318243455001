import * as THREE from "three";

// Extend the base MeshPhysicalMaterial class to add support for custom mix uniform
class MeshPhysicalMaterialAbs extends THREE.MeshPhysicalMaterial {
  constructor(parameters) {
    super(parameters);
		this._mix = parameters.mix || 0.0;
		this._mixUniform = { value: this._mix };
    this.onBeforeCompile = (shader) => {
      shader.uniforms = {
        ...shader.uniforms,
        uMix: this._mixUniform,
      };

			shader.defines.USE_UV = true;

			shader.fragmentShader = `
				uniform float uMix;
			` + shader.fragmentShader;

      // Custom gl_FragColor
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <dithering_fragment>",
        `
					#include <dithering_fragment>
					// Reveal gl_FragColor along the y uv axis as uMix increases
					gl_FragColor.a = smoothstep(vUv.y, vUv.y + 0.05, uMix);
					// gl_FragColor.a = clamp(0.0, 0.75, smoothstep(vUv.y, vUv.y + 0.05, uMix));
				`
      );
    };
  }
  get mix() {
    return this._mix;
  }
  set mix(value) {
    this._mix = value;
    this._mixUniform.value = this._mix;
  }
}

export default MeshPhysicalMaterialAbs;
